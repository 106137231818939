.hvr-grow-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}

.hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active { box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.anchor {
  fill: white;
}

.heading:hover .anchor {
  fill: #24292e;
}

.social {
  font-size: 20px;
  letter-spacing: 5px;
}
.social a {
  color: currentColor;
}
.social a:hover {
  color: #3198D8;
}

.navTarget:hover {
  /*background-color: rgb(74, 91, 113);*/
  cursor: pointer;
}

.navTarget:active {
  /*background-color: rgb(84, 101, 123);*/
}

.colored-background {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.btn-dark {
  background: linear-gradient(-45deg, #23d5ab, #23a6d5, #e73c7e, #ee7752);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-color: unset;
}

.btn-dark:hover {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-color: black;
}

#logo-text a:hover{
  text-decoration: none;
  color: white;
}

#logo-text a{
  color: white;
}
