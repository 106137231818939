.colored {
  display: inline-block;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.typer-line {
  display: inline-block;
}

.video-wrapper {
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 20px;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


span {
}

.header {
  font-size: 70px;
  font-weight: bold;
  margin-bottom: 30px;
}

.subHeader {
  font-size: 35px;
  font-weight: bold;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-spacer {
  height: 250px;
}
